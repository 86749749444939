import React, { useEffect } from "react";
import "./about.css";
import Carousel from "components/Carousel";
import choirDirector from "../../assets/images/cheffe.jpg";
import pianist from "../../assets/images/pianist.JPG";
import infoLogo from "../../assets/images/info.png";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="about-top">
        <div className="about-title-container">
          <h1 className="about-title">À propos de nous</h1>
        </div>
      </div>
      <div className="about-container">
        <div className="carousel-container">
          <Carousel />
        </div>
        <h3 className="about-text">
          Nous sommes un groupe d'une quarantaine de passionnés de musique
          vocale, composée de chanteurs de tous niveaux. Notre objectif est de
          promouvoir et de célébrer l'art du chant choral, en offrant des
          opportunités d'apprentissage,de performance mais aussi de donner du
          plaisir à un public.
        </h3>
        <div className="about-card" data-aos="fade-right">
          <img src={choirDirector} className="about-img" alt="choir-director" />
          <div className="about-card-container">
            <h1 className="about-card-title">La cheffe de chœur</h1>
            <h3 className="about-card-text">
              Notre chorale est dirigée par une cheffe de chœur expérimentée,
              qui aime partager sa passion pour la musique vocale. Elle
              travaille avec les choristes pour développer leur technique
              vocale, leur musicalité et leur compréhension de la musique, tout
              en encourageant une atmosphère de camaraderie et de soutien
              mutuel.
            </h3>
          </div>
        </div>
        <div className="about-card" data-aos="fade-left">
          <div className="about-card-container">
            <h1 className="about-card-title">La pianiste</h1>
            <h3 className="about-card-text">
              Nous sommes accompagnés par une pianiste qui nous aide à soutenir
              nos voix, maintenir le tempo et la tonalité de la musique. Elle
              travaille avec la cheffe de ch&oelig;ur pour préparer les répétitions
              et les représentations. La pianiste a un rôle essentiel pour que
              les choristes aient la meilleure expérience musicale possible.
            </h3>
          </div>
          <img src={pianist} className="about-img" alt="choir-director" />
        </div>
        <h3 className="about-text">
          Que vous soyez un/e chanteur/se débutant/e cherchant à développer vos
          compétences vocales, un/e chanteur/se expérimenté/e à la recherche
          d'un nouveau défi musical, ou simplement un amateur/trice de musique
          qui cherche à rejoindre notre chorale chaleureuse et accueillante,
          nous avons une place pour vous dans notre groupe.
        </h3>
        <div className="repetitions-card">
          <img src={infoLogo} style={{ marginRight: "10px" }} alt="info" />
          <h3 className="about-text" style={{ color: "black" }}>
            Nos répétitions se déroulent tous les lundi et vendredi de 14h00 à
            16h30 dans la salle Saint-Louis de la paroisse d'Auriol située au 4
            rue de la cave 13390 Auriol
          </h3>
        </div>
        <h3 className="about-text">
          Nous espérons que vous trouverez notre site utile et informatif, et
          que vous vous joindrez à nous pour célébrer la beauté et la puissance
          de la musique vocale. Si vous avez des questions ou si vous souhaitez
          vous impliquer, n'hésitez pas à nous contacter. Merci de votre visite
          !
        </h3>
      </div>
    </>
  );
};

export default About;
