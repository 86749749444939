import React, { useEffect, useState } from "react";
import "./map.css";
import GoogleMapReact from "google-map-react";
import { LocationPin } from "components/LocationPin";

const Map = (props) => {
  const [cordinates, setCordinates] = useState([
    43.3702646022293, 5.634519626591176,
  ]);

  useEffect(() => {
    setCordinates(props.center);
  }, [props.center]);

  return (
    <>
      <div className="map">
        <div className="google-map">
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{
              key: "AIzaSyBvn9NgljvIaJv8DPLWvqrGRgrfaTLfa8I",
            }}
            center={cordinates}
            defaultZoom={16}
          >
            <LocationPin lat={cordinates[0]} lng={cordinates[1]} />
          </GoogleMapReact>
        </div>
      </div>
    </>
  );
};

export default Map;
