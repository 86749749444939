import { atom } from "jotai";
import Cookies from "js-cookie";

export const userAtom = atom(
  Cookies.get("user") ? JSON.parse(Cookies.get("user")) : ""
);
export const JWT = atom(Cookies.get("token") ? Cookies.get("token") : "");
export const isAdmin = atom(
  Cookies.get("isAdmin") ? Cookies.get("isAdmin") : "false"
);
export const avatarAtom = atom(
  Cookies.get("avatar") ? Cookies.get("avatar") : ""
);
export const defaultAvatarFileAtom = atom(
  Cookies.get("defaultAvatarFile") ? Cookies.get("defaultAvatarFile") : ""
);
export const lastAvatarFileAtom = atom(
  Cookies.get("lastAvatarFile") ? Cookies.get("lastAvatarFile") : ""
);
