import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { useAtomValue } from "jotai";
import { userAtom } from "store/atoms";
import assocLogo from "../../assets/images/logo-assoc.png";

const Footer = () => {
  const user = useAtomValue(userAtom);

  return (
    <>
      <div className="footer-container">
        <div className="logo-footer-container">
          <img
            src={assocLogo}
            className="assoc-logo-footer"
            alt="association logo"
          />
        </div>
        <div className="links-footer-container">
          <div className="section1-footer">
            <h3 className="footer-titles">Liens</h3>
            <Link
              to="/a-propos"
              className="footer-link"
              style={{ textDecoration: "none" }}
            >
              À propos de nous
            </Link>
            <Link
              to="/evenements"
              className="footer-link"
              style={{ textDecoration: "none" }}
            >
              Les évènements
            </Link>
            <Link
              to="/galerie"
              className="footer-link"
              style={{ textDecoration: "none" }}
            >
              Galerie
            </Link>
            <Link
              to="/livre-dor"
              className="footer-link"
              style={{ textDecoration: "none" }}
            >
              Le livre d'or
            </Link>
            <Link
              to="/contact"
              className="footer-link"
              style={{ textDecoration: "none" }}
            >
              Contact
            </Link>
          </div>
          <div className="section2">
            <div className="section2-b">
              <h3 className="footer-titles">Espace privé</h3>
              {user.id ? (
                <>
                  <Link
                    to="/profil"
                    className="footer-link"
                    style={{ textDecoration: "none" }}
                  >
                    Mon profil
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/inscription"
                    className="footer-link"
                    style={{ textDecoration: "none" }}
                  >
                    S'inscrire
                  </Link>
                  <Link
                    to="/connexion"
                    className="footer-link"
                    style={{ textDecoration: "none" }}
                  >
                    Se connecter
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-end">
        <h5>ⓒ Chante La Vie - 2023. Tous droits réservés</h5>
        <Link to="/notices" className="notices-btn">
          Mentions légales et politique de cookies
        </Link>
      </div>
    </>
  );
};

export default Footer;
