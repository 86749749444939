import React, { useEffect } from "react";
import "./gallery.css";
import photosIcon from "../../assets/images/photos.png";
import videosIcon from "../../assets/images/videos.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Gallery = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    window.scrollTo(0, 0);
  });

  function toggleNav() {
    document
      .getElementsByClassName("gallery-card-logo")[0]
      .classList.toggle("gallery-card-logo-animation");
  }

  function toggleNav2() {
    document
      .getElementsByClassName("gallery-card-logo")[1]
      .classList.toggle("gallery-card-logo-animation2");
  }

  return (
    <>
      <div className="gallery-container">
        <div className="cards-container">
          <Link
            to="/galerie/photos"
            data-aos="fade-right"
            style={{ textDecoration: "none" }}
          >
            <div className="gallery-card" onMouseEnter={toggleNav}>
              <img
                src={photosIcon}
                style={{ width: "90px", height: "90px" }}
                alt="logo-photos"
                className="gallery-card-logo"
              />
              <h1 className="gallery-card-title">Photos</h1>
            </div>
          </Link>
          <Link
            to="/galerie/videos"
            data-aos="fade-left"
            style={{ textDecoration: "none" }}
          >
            <div
              className="gallery-card"
              id="gallery-card2"
              onMouseEnter={toggleNav2}
            >
              <img
                src={videosIcon}
                style={{ width: "90px", height: "90px" }}
                alt="logo-videos"
                className="gallery-card-logo"
              />
              <h1 className="gallery-card-title">Videos</h1>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Gallery;
