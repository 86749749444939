import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import carousel1 from "../../assets/images/carousel3.JPG";
import carousel2 from "../../assets/images/home.JPG";
import carousel3 from "../../assets/images/carousel2.jpeg";
//import carousel4 from "../../assets/images/photo3.jpg";

const CarouselAbout = () => {
  return (
    <Carousel
      className="carousel"
      infiniteLoop
      useKeyboardArrows
      autoPlay
      showThumbs={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        const defStyle = {
          margin: 5,
          color: "white",
          fontWeight: 800,
          fontSize: 70,
          cursor: "pointer",
        };
        const style = isSelected
          ? { ...defStyle, color: "#d2601a" }
          : { ...defStyle };
        return (
          <span
            style={style}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
          >
            {"__"}
          </span>
        );
      }}
    >
      <div>
        <img src={carousel1} alt="carousel-img" />
      </div>
      <div>
        <img src={carousel2} alt="carousel-img" />
      </div>
      <div>
        <img src={carousel3} alt="carousel-img" />
      </div>
    </Carousel>
  );
};

export default CarouselAbout;
