import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useSetAtom } from "jotai";
import { userAtom, JWT, isAdmin, defaultAvatarFileAtom } from "store/atoms";
import "./registerForm.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";

const RegisterForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const setUser = useSetAtom(userAtom);
  const setToken = useSetAtom(JWT);
  const setAdmin = useSetAtom(isAdmin);
  const navigate = useNavigate();
  const setDefaultAvatar = useSetAtom(defaultAvatarFileAtom);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  function fetchData(e) {
    e.preventDefault();
    if (password !== passwordConfirm) {
      errorNotifyPassword();
      return;
    }
    if (email === "" || password === "") {
      errorEmptyInputs();
      return;
    }
    fetch("https://clv-app.fly.dev/users", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          email: email,
          password: password,
        },
      }),
    })
      .then((response) => {
        setToken(response.headers.get("Authorization"));
        Cookies.set("token", response.headers.get("Authorization"), {
          sameSite: "none",
          secure: true,
        });
        return response.json();
      })
      .then((response) => {
        if (response.message === "Signed up sucessfully.") {
          response.user.password = "**Crypted**";
          setUser(response.user);
          Cookies.set("user", JSON.stringify(response.user), {
            sameSite: "none",
            secure: true,
          });
          if (response.user.email === "chantelavie13390@gmail.com") {
            setAdmin("true");
            Cookies.set("isAdmin", "true", {
              sameSite: "none",
              secure: true,
            });
          } else {
            setAdmin("false");
            Cookies.set("isAdmin", "false", {
              sameSite: "none",
              secure: true,
            });
          }

          setTimeout(function () {
            navigate("/");
          }, 500);
          successNotify();
        } else {
          errorNotify();
          setToken("");
        }
      });
    const defaultAvatarUrl =
      "https://res.cloudinary.com/dytmjg2cx/image/upload/v1680864769/360_F_209370065_JLXhrc5inEmGl52SyvSPeVB23hB6IjrR_t08uuc.jpg";
    const defaultAvatarFileName = defaultAvatarUrl.substring(
      defaultAvatarUrl.lastIndexOf("/") + 1
    );
    fetch(defaultAvatarUrl)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new File([blob], `${defaultAvatarFileName}`, {
            type: blob.type,
          })
      )
      .then((file) => {
        setDefaultAvatar(file);
        Cookies.set("defaultAvatarFile", file, {
          sameSite: "none",
          secure: true,
        });
      });
  }

  function successNotify() {
    toast.success("Inscription réussie ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorNotify() {
    toast.error("Cette adresse email est déjà utilisée.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorNotifyPassword() {
    toast.error("Les mot de passe doivent être identiques.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorEmptyInputs() {
    toast.error("Veuillez remplir tous les champs du formulaire", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <>
      <div className="card" data-aos="fade-down">
        <form onSubmit={fetchData} className="box">
          <h1 className="log-title">Inscription</h1>
          <p className="info-registration text-muted">
            {" "}
            Veuillez remplir le formulaire ci-dessous
            <br />
            <br />
            Vous êtes choriste chez nous ? Inscrivez-vous pour faire part de
            votre participation à de nombreux évènements ainsi qu'améliorer la
            communication pour une meilleure organisation de l'association. 👍
          </p>
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
          />

          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
          />
          <input
            type="password"
            name="passwordConfirmation"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            placeholder="Confirmation mot de passe"
          />
          <Link
            to="/connexion"
            className="forgot text-muted"
            style={{ textDecoration: "none" }}
          >
            Vous avez déjà un compte?
          </Link>
          <input type="submit" name="" value="S'inscrire" />
        </form>
      </div>
    </>
  );
};

export default RegisterForm;
