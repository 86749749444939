import React, { useEffect } from "react";
import "./contact.css";
import AOS from "aos";
import "aos/dist/aos.css";
import sendLogo from "../../assets/images/send.png";
import { useAtomValue } from "jotai";
import { userAtom } from "store/atoms";
import contactLogo from "../../assets/images/contact.png";
import locationLogo from "../../assets/images/localisation.png";
import phoneLogo from "../../assets/images/phone.png";
import emailLogo from "../../assets/images/email-adress.png";
import Map from "components/Map";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const Contact = () => {
  const user = useAtomValue(userAtom);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    window.scrollTo(0, 0);
  }, []);

  function sendEmail(e) {
    e.preventDefault();
    let lastname = e.target.elements.lastname.value;
    let firstname = e.target.elements.firstname.value;
    let email = e.target.elements.email.value;
    let message = e.target.elements.text.value;

    if (!lastname) {
      errorLastNameNotify();
      return;
    }
    if (!firstname) {
      errorFirstNameNotify();
      return;
    }
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errorMailNotify();
      return;
    }
    if (!message) {
      errorMessageNotify();
      return;
    }

    emailjs
      .sendForm(
        "service_0ekpx6s",
        "template_8tdtxdt",
        e.target,
        "sVsj_7QNcD8a-Qu0V"
      )
      .then(
        (result) => {
          console.log(result.text);
          successNotify();
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  function successNotify() {
    toast.success("Message envoyé 📬", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorLastNameNotify() {
    toast.error("Il manque votre nom !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorFirstNameNotify() {
    toast.error("Il manque votre prénom !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorMailNotify() {
    toast.error("Il manque votre adresse e-mail ou le format n'est pas bon !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorMessageNotify() {
    toast.error("Le message ne peut pas être vide !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <>
      <div className="contact">
        <div className="contact-top">
          <div className="contact-title-container">
            <h1 className="contact-title">Contact</h1>
            <img
              src={contactLogo}
              className="contact-logo"
              alt="calendar logo"
            />
          </div>
        </div>
        <div className="contact-section1">
          <div className="contact-section1-left">
            <div className="contact-section1-left-container">
              <img
                className="section1-logo"
                src={locationLogo}
                alt="location-logo"
              />
              <h3 className="contact-left-text">
                &nbsp;Place de la libération 13390 AURIOL
              </h3>
            </div>
            <div className="contact-section1-left-container">
              <img className="section1-logo" src={phoneLogo} alt="phone-logo" />
              <h3 className="contact-left-text">&nbsp;06.37.80.72.29</h3>
            </div>
            <div className="contact-section1-left-container">
              <img className="section1-logo" src={emailLogo} alt="email-logo" />
              <h3 className="contact-left-text">
                &nbsp;chantelavie13390@gmail.com
              </h3>
            </div>
          </div>
          <div className="contact-section1-right">
            <div data-aos="flip-up" className="map-container">
              <Map center={[43.3702646022293, 5.634519626591176]} />
            </div>
          </div>
        </div>
        <div className="contact-section2">
          <h2 id="title1">Vous recherchez une chorale près de chez vous ?</h2>
          <h3 id="title2">
            Pour toute demande, complétez notre formulaire de contact
            ci-dessous. <br /> Vous serez recontacté dans les plus bref
            délais.(Le numéro de tél. n'est pas obligatoire)
          </h3>
        </div>
        <form className="contact-form" onSubmit={sendEmail}>
          <input
            name="lastname"
            type="text"
            className="feedback-input"
            defaultValue={user.id ? user.last_name : ""}
            placeholder={user.id && user.last_name !== "" ? "" : "Nom"}
          />
          <input
            name="firstname"
            type="text"
            className="feedback-input"
            defaultValue={user.id ? user.first_name : ""}
            placeholder={user.id && user.first_name !== "" ? "" : "Prénom"}
          />
          <input
            name="email"
            type="text"
            className="feedback-input"
            defaultValue={user.id ? user.email : ""}
            placeholder={user.id && user.email !== "" ? "" : "E-mail"}
          />
          <input
            name="tel"
            type="tel"
            className="feedback-input"
            defaultValue={user.id ? user.phone : ""}
            placeholder={user.id && user.phone !== "" ? "" : "N° Tél."}
          />
          <textarea
            name="text"
            className="feedback-input"
            placeholder="Message"
          ></textarea>
          <button type="submit">
            <img
              className="send-logo"
              style={{ marginTop: "5px" }}
              src={sendLogo}
              alt="logo-envoi-mail"
            />
          </button>
        </form>
      </div>
    </>
  );
};

export default Contact;
