import React, { useEffect, useState } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import collapseIcon from "../../assets/images/collapse-icon.png";
import { useSetAtom, useAtomValue } from "jotai";
import {
  userAtom,
  JWT,
  isAdmin,
  avatarAtom,
  lastAvatarFileAtom,
} from "store/atoms";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import burgerLogo from "../../assets/images/burger.png";
import crossLogo from "../../assets/images/cross.png";
import assocLogo from "../../assets/images/logo-assoc.png";

const Navbar = () => {
  const setUser = useSetAtom(userAtom);
  const setToken = useSetAtom(JWT);
  const setAdmin = useSetAtom(isAdmin);
  const setAvatar = useSetAtom(avatarAtom);
  const setLastAvatarFile = useSetAtom(lastAvatarFileAtom);
  const jwt = useAtomValue(JWT);
  const admin = useAtomValue(isAdmin);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [menuClass, setMenuClass] = useState("menu");
  const [open, setOpen] = useState(false);
  const [activeIcon, setActiveIcon] = useState(false);

  function updateMenu() {
    if (!isNavExpanded) {
      setMenuClass("menu expanded");
    } else {
      setMenuClass("menu");
    }
    setIsNavExpanded(!isNavExpanded);
  }

  const toggle = () => {
    setOpen(!open);
  };

  const toggleIcon = () => {
    setActiveIcon(!activeIcon);
  };

  useEffect(() => {
    if (admin === "false") {
      setAdmin("false");
    }
  });

  function logout() {
    fetch("https://clv-app.fly.dev/users/sign_out", {
      method: "delete",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
    });
    Cookies.remove("token", {
      sameSite: "none",
      secure: true,
    });
    Cookies.remove("user", {
      sameSite: "none",
      secure: true,
    });
    Cookies.remove("isAdmin", {
      sameSite: "none",
      secure: true,
    });
    Cookies.remove("avatar", {
      sameSite: "none",
      secure: true,
    });
    Cookies.remove("lastAvatarFile", {
      sameSite: "none",
      secure: true,
    });

    setToken("");
    setUser([]);
    setAdmin("false");
    setAvatar("");
    setLastAvatarFile("");
    successNotify();
  }

  function successNotify() {
    toast.success("A bientôt ! 👋", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <>
      <nav className="navbar-container" role="navigation">
        <div className="navbar">
          <Link
            to="/"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="assoc-logo"
              src={assocLogo}
              alt="association logo"
            />
          </Link>
          <Link
            to="/a-propos"
            className="nav-links"
            style={{ textDecoration: "none" }}
          >
            A propos
          </Link>
          <Link
            to="/evenements"
            className="nav-links"
            style={{ textDecoration: "none" }}
          >
            Évènements
          </Link>
          <Link
            to="/galerie"
            className="nav-links"
            style={{ textDecoration: "none" }}
          >
            Galerie
          </Link>

          <Link
            to="/livre-dor"
            className="nav-links"
            style={{ textDecoration: "none" }}
          >
            Livre d'or
          </Link>
          <Link
            to="/contact"
            className="nav-links"
            style={{ textDecoration: "none" }}
          >
            Contact
          </Link>

          {isNavExpanded ? (
            <img
              src={crossLogo}
              onClick={updateMenu}
              className="burger-logo"
              alt="close burger logo"
            />
          ) : (
            <img
              src={burgerLogo}
              onClick={updateMenu}
              className="burger-logo"
              alt="burger logo"
            />
          )}
        </div>
      </nav>
      <div
        className="collapse"
        onClick={() => {
          toggle();
          toggleIcon();
        }}
      >
        <div className="collapse-btn-container">
          <button className="collapse-btn">Espace choriste</button>
          <img
            src={collapseIcon}
            className={`${
              activeIcon ? "collapse-icon-animation" : "collapse-icon"
            }`}
            alt="collapse-icon"
          />
        </div>
        {open && (
          <div className={`toggle ${open ? "open" : ""}`}>
            {jwt === "" ? (
              <>
                <Link
                  to="/inscription"
                  className="collapse-link"
                  style={{ textDecoration: "none" }}
                >
                  S'inscrire
                </Link>
                <Link
                  to="/connexion"
                  className="collapse-link"
                  style={{ textDecoration: "none" }}
                >
                  Se connecter
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/profil"
                  className="collapse-link"
                  style={{ textDecoration: "none" }}
                >
                  Mon profil
                </Link>
                <Link
                  to="/"
                  className="collapse-link"
                  style={{ textDecoration: "none" }}
                  onClick={logout}
                >
                  Se déconnecter
                </Link>
              </>
            )}
          </div>
        )}
      </div>
      <div className={menuClass} id="menu">
        <div className="login-menu-container">
          {jwt === "" ? (
            <>
              <Link
                to="/inscription"
                className="login-btn"
                style={{ textDecoration: "none" }}
                onClick={updateMenu}
              >
                S'inscrire
              </Link>
              &nbsp;&nbsp;
              <Link
                to="/connexion"
                className="login-btn"
                style={{ textDecoration: "none" }}
                onClick={updateMenu}
              >
                Se connecter
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/profil"
                className="login-btn"
                style={{ textDecoration: "none" }}
                onClick={updateMenu}
              >
                Mon profil
              </Link>
              &nbsp;&nbsp;
              <Link
                to="/connexion"
                className="login-btn"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  logout();
                  updateMenu();
                }}
              >
                Se déconnecter
              </Link>
            </>
          )}
        </div>
        <div className="menu-links-container">
          <Link
            to="/"
            className="menu-links"
            style={{ textDecoration: "none" }}
            onClick={updateMenu}
          >
            {" "}
            Accueil
          </Link>
          <Link
            to="/evenements"
            className="menu-links"
            style={{ textDecoration: "none" }}
            onClick={updateMenu}
          >
            Évènements
          </Link>
          <Link
            to="/a-propos"
            className="menu-links"
            style={{ textDecoration: "none" }}
            onClick={updateMenu}
          >
            A propos
          </Link>
          <Link
            to="/galerie"
            className="menu-links"
            style={{ textDecoration: "none" }}
            onClick={updateMenu}
          >
            Galerie
          </Link>
          <Link
            to="/livre-dor"
            className="menu-links"
            style={{ textDecoration: "none" }}
            onClick={updateMenu}
          >
            Livre d'or
          </Link>
          <Link
            to="/contact"
            className="menu-links"
            style={{ textDecoration: "none" }}
            onClick={updateMenu}
          >
            Contact
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
