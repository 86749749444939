import React, { useState, useEffect } from "react";
import "./events.css";
import calendarLogo from "../../assets/images/calendar.png";
import { useAtomValue } from "jotai";
import { isAdmin } from "store/atoms";
import Masonry from "react-masonry-css";
import CreateEvent from "components/CreateEvent";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Events = () => {
  const [isActive, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [pastList, setPastList] = useState([]);
  const [futurList, setFuturList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  var dateNow = new Date();
  const admin = useAtomValue(isAdmin);
  const breakpointColumnsObj = {
    default: 3,
    1400: 2,
    700: 2,
    500: 2,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch("https://clv-app.fly.dev/events/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        var pastArray = [];
        var futurArray = [];
        response.map((event) => {
          var date = new Date(event.start_date);
          date.setDate(date.getDate() + 1);
          if (date < dateNow) {
            pastArray.push(event);
            setPastList(pastArray);
          } else {
            futurArray.push(event);
            setFuturList(futurArray);
          }
        });
        setIsLoading(false);
      });
  }, []);

  function displayForm() {
    if (!isActive) {
      document.getElementsByClassName("event-form")[0].style.display = "flex";
    } else {
      document.getElementsByClassName("event-form")[0].style.display = "none";
    }
    setActive(!isActive);
  }

  return (
    <>
      <div className="events-container">
        <div className="events-top">
          <div className="events-title-container">
            <h1 className="events-title">Évènements</h1>
            <img
              src={calendarLogo}
              className="calendar-logo"
              alt="calendar logo"
            />
          </div>
        </div>
        {admin === "true" ? (
          <button
            type="button"
            onClick={() => {
              setActive(!isActive);
              displayForm();
            }}
            className="create-event-btn"
          >
            Créer un évènement
          </button>
        ) : (
          <></>
        )}

        <CreateEvent />
        <div className="filter-btn-container">
          <button
            type="button"
            className={`filter-btn ${activeTab === 1 && "active"}`}
            id="past-btn"
            onClick={(e) => setActiveTab(1)}
          >
            Passés
          </button>
          <button
            type="button"
            className={`filter-btn ${activeTab === 2 && "active"}`}
            id="futur-btn"
            onClick={(e) => setActiveTab(2)}
          >
            À venir
          </button>
        </div>
        <div className="events-line"></div>
        <h1 className="past-events-title">
          {activeTab === 1 ? "Évènements passés" : "Évènements à venir"}
        </h1>
        {isLoading ? (
          <FontAwesomeIcon
            style={{ paddingTop: "100px", paddingBottom: "100px" }}
            size="5x"
            color="#1d3c45"
            className="spinner"
            icon={faSpinner}
          ></FontAwesomeIcon>
        ) : null}
        <Masonry
          className="my-masonry-grid"
          breakpointCols={breakpointColumnsObj}
        >
          {activeTab === 1
            ? pastList.map((evnt) => (
                <figure key={evnt.id} className="image-block">
                  <Link
                    to={`/evenement/${evnt.id}`}
                    style={{
                      textDecoration: "none",
                      border: "none",
                      height: "100%",
                    }}
                    className="card-event-btn"
                  >
                    <img
                      src={evnt.image_url}
                      alt="event picture"
                      className="image-event-card"
                    />
                    <figcaption
                      style={{ backgroundColor: "black", opacity: "0.6" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h2 className="event-title">
                          &nbsp;{evnt.title}&nbsp;
                        </h2>
                      </div>
                      <p className="event-text">
                        &nbsp;Date :{" "}
                        {evnt.start_date
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                        &nbsp;
                        <br />
                        <br />
                        {evnt.location}
                      </p>
                    </figcaption>
                  </Link>
                </figure>
              ))
            : futurList.map((evnt) => (
                <figure key={evnt.id} className="image-block">
                  <Link
                    to={`/evenement/${evnt.id}`}
                    style={{
                      textDecoration: "none",
                      border: "none",
                      height: "100%",
                    }}
                    className="card-event-btn"
                  >
                    <img
                      src={evnt.image_url}
                      alt="event picture"
                      className="image-event-card"
                    />
                    <figcaption
                      style={{ backgroundColor: "black", opacity: "0.6" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h2 className="event-title">
                          &nbsp;{evnt.title}&nbsp;
                        </h2>
                      </div>
                      <p className="event-text">
                        &nbsp;Date :{" "}
                        {evnt.start_date
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                        &nbsp;
                        <br />
                        <br />
                        {evnt.location}
                      </p>
                    </figcaption>
                  </Link>
                </figure>
              ))}
        </Masonry>
      </div>
    </>
  );
};

export default Events;
