import React, { useState, useEffect } from "react";
import "./loginForm.css";
import { useSetAtom } from "jotai";
import { userAtom, JWT, isAdmin, defaultAvatarFileAtom } from "store/atoms";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setToken = useSetAtom(JWT);
  const setUser = useSetAtom(userAtom);
  const setAdmin = useSetAtom(isAdmin);
  const navigate = useNavigate();
  const setDefaultAvatar = useSetAtom(defaultAvatarFileAtom);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  function fetchData(e) {
    e.preventDefault();
    fetch("https://clv-app.fly.dev/users/sign_in", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          email: email,
          password: password,
        },
      }),
    })
      .then((response) => {
        setToken(response.headers.get("Authorization"));
        Cookies.set("token", response.headers.get("Authorization"), {
          sameSite: "none",
          secure: true,
        });
        return response.json();
      })
      .then((response) => {
        if (response.user) {
          response.user.password = "**Crypted**";
          setUser(response.user);
          Cookies.set("user", JSON.stringify(response.user), {
            sameSite: "none",
            secure: true,
          });
          if (response.user.email === "chantelavie13390@gmail.com") {
            setAdmin("true");
            Cookies.set("isAdmin", "true", {
              sameSite: "none",
              secure: true,
            });
          } else {
            setAdmin("false");
            Cookies.set("isAdmin", "false", {
              sameSite: "none",
              secure: true,
            });
          }
          setTimeout(function () {
            navigate("/");
          }, 500);
          successNotify();
        } else {
          errorNotify();
          setToken("");
        }
      });
    const defaultAvatarUrl =
      "https://res.cloudinary.com/dytmjg2cx/image/upload/v1680864769/360_F_209370065_JLXhrc5inEmGl52SyvSPeVB23hB6IjrR_t08uuc.jpg";
    const defaultAvatarFileName = defaultAvatarUrl.substring(
      defaultAvatarUrl.lastIndexOf("/") + 1
    );
    fetch(defaultAvatarUrl)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new File([blob], `${defaultAvatarFileName}`, {
            type: blob.type,
          })
      )
      .then((file) => {
        setDefaultAvatar(file);
        Cookies.set("defaultAvatarFile", file, {
          sameSite: "none",
          secure: true,
        });
      });
  }

  function successNotify() {
    toast.success("Connexion réussie", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  function errorNotify() {
    toast.error("Un problème est survenu. Veuillez Réessayer.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <>
      <div className="card" data-aos="fade-down">
        <form className="box" onSubmit={fetchData}>
          <h1 className="log-title">Connexion</h1>
          <p className="info-registration text-muted">
            Veuillez remplir le formulaire ci-dessous pour vous connecté à un
            compte existant
          </p>
          <input
            type="text"
            name="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Link
              to="/oubli-mdp"
              className="forgot text-muted"
              style={{ textDecoration: "none" }}
            >
              Mot de passe oublié?
            </Link>
            <Link
              to="/inscription"
              className="forgot text-muted"
              style={{ textDecoration: "none" }}
            >
              Vous n'avez pas encore de compte ?
            </Link>
          </div>
          <input type="submit" value="Se connecter" />
        </form>
      </div>
    </>
  );
};

export default LoginForm;
