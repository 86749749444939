import React, { useState } from "react";
import "./createEvent.css";
import { useAtomValue } from "jotai";
import { JWT } from "store/atoms";
import addLogo from "../../assets/images/add.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateEvent = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startHour, setStartHour] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const jwt = useAtomValue(JWT);
  const navigate = useNavigate();
  var dateNow = new Date();

  function createEvent() {
    fetch("https://clv-app.fly.dev/events/", {
      method: "post",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: {
          title: title,
          description: description,
          location: city,
          address: address,
          start_date: startDate,
          start_hour: startHour,
          latitude: latitude,
          longitude: longitude,
          image_url: imageUrl,
        },
      }),
    })
      .then((response) => {
        document.getElementsByClassName("event-form")[0].style.display = "none";
        return response.json();
      })
      .catch((err) => console.error(err));
    successNotify();
    navigate("/evenements");
  }

  function successNotify() {
    toast.success(
      `L'évènement "${title}" qui ${
        new Date(startDate) < dateNow ? "à eu" : "aura"
      } lieu à ${city} le ${startDate
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/")} est bien ajouté`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  }

  return (
    <>
      <form className="event-form">
        <input
          name="title"
          type="text"
          className="feedback-input"
          placeholder="Titre"
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          name="city"
          type="text"
          className="feedback-input"
          placeholder="Ville"
          onChange={(e) => setCity(e.target.value)}
        />
        <input
          name="address"
          type="text"
          className="feedback-input"
          placeholder="ex: 13390 Pl. de la Libération, Auriol"
          onChange={(e) => setAddress(e.target.value)}
        />
        <input
          name="start_date"
          type="date"
          className="feedback-input"
          placeholder="Date de début"
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          name="start_hour"
          type="time"
          className="feedback-input"
          placeholder="Heure de début"
          onChange={(e) => setStartHour(e.target.value)}
        />
        <input
          name="latitude"
          type="text"
          className="feedback-input"
          placeholder="Latitude"
          onChange={(e) => setLatitude(e.target.value)}
        />
        <input
          name="longitude"
          type="text"
          className="feedback-input"
          placeholder="Longitude"
          onChange={(e) => setLongitude(e.target.value)}
        />
        <input
          name="image_url"
          type="text"
          className="feedback-input"
          placeholder="URL de l'image"
          onChange={(e) => setImageUrl(e.target.value)}
        />
        <textarea
          name="text"
          className="feedback-input"
          placeholder="Description"
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <button className="add-event-btn" onClick={createEvent} type="button">
          <img
            className="add-logo"
            src={addLogo}
            style={{ marginTop: "5px" }}
            alt="create-event-logo"
          />
        </button>
      </form>
    </>
  );
};

export default CreateEvent;
