import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./emailSent.css";
import confirmLogo from "../../assets/images/confirmation.png";
import AOS from "aos";
import "aos/dist/aos.css";

const EmailSent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    setTimeout(function () {
      navigate("/");
    }, 3000);
  }, []);

  return (
    <>
      <div data-aos="fade-down" className="email-sent-container">
        <img src={confirmLogo} className="confirm-logo" alt="confirmation" />
        <h1 className="email-sent-title">E-MAIL ENVOYÉ</h1>
        <h3 className="email-sent-text">
          Un e-mail de récupération de mot de passe vient de vous être envoyé
          sur l'adresse associée à votre compte.
        </h3>
      </div>
    </>
  );
};

export default EmailSent;
