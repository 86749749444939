import React, { useEffect } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import quoteRight from "../../assets/images/quote-right.png";
import quoteLeft from "../../assets/images/quote-left.png";
import AOS from "aos";
import "aos/dist/aos.css";
import photo6 from "../../assets/images/photo6.jpg";
import homePicture from "../../assets/images/home.JPG";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="homepage-section1">
        <img src={homePicture} className="home-picture" alt="home picture" />
        <div className="video-content">
          <div className="video-card">
            <h2 className="title1-video">Chante la vie</h2>
            <h3 className="title2-video">chorale de la commune de Auriol</h3>
            <p className="video-card-text">
              Bienvenue sur le site de notre association !<br />
              <br />
              La chorale est un excellent moyen pour s'épanouir
              par le <br />  chant et pour passer un bon moment en communauté.
              <br />
              Venez nous découvrir lors de nos
              représentations.
            </p>
            <Link
              className="home-btn"
              style={{ textDecoration: "none" }}
              to="/evenements"
            >
              Voir
            </Link>
          </div>
        </div>
      </div>
      <div className="citation-container" data-aos="fade-up">
        <h2 className="citation">
          <img
            src={quoteLeft}
            style={{ width: "40px", height: "40px", padding: "5px" }}
            alt="guillemets gauche"
          />
          &nbsp;&nbsp;Un chef de chorale dit :<br /> Nous unissons nos coeurs,
          quand le ch&oelig;ur chante à l'unisson.&nbsp;&nbsp;
          <img
            src={quoteRight}
            style={{ width: "40px", height: "40px" }}
            alt="guillemets droit"
          />
        </h2>
        <h2 className="citation-author">Christophe Hainaut</h2>
      </div>
      <div className="homepage-section2">
        <div className="homepage-section2-left">
          <img
            data-aos="fade-right"
            src={photo6}
            alt="photo-chœur"
            className="img1-home"
          />
        </div>
        <div className="homepage-section2-right" data-aos="fade-up">
          <h2 className="homepage-section2-right-title">Nous rejoindre</h2>
          <p className="homepage-section2-right-text">
            Si vous aimez chanter et que vous
            voulez rejoindre une chorale dynamique, prenez contact avec nous.
          </p>
          <Link
            to="/contact"
            className="home-btn-joinus"
            style={{ textDecoration: "none" }}
          >
            Contact
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
