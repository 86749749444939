import React, { useEffect } from "react";
import "./notices.css";

const Notices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="notices-container">
        <h1 className="notices-title">Politique de cookies</h1>
        <p className="notices-text">
          Nous utilisons des cookies pour améliorer l'expérience de nos
          utilisateurs sur notre site. Les cookies sont des fichiers texte
          stockés sur votre ordinateur ou votre appareil mobile lorsque vous
          naviguez sur notre site.
        </p>
        <p className="notices-text">
          Les types de cookies que nous utilisons sur notre site sont les
          suivants :
        </p>
        <ul className="notices-cookies-list">
          <li>
            Cookies de sessions : ces cookies sont nécessaires pour maintenir
            votre session active sur notre site, ce qui vous permet de naviguer
            et d'utiliser nos services sans avoir à vous reconnecter à chaque
            page.
          </li>
          <li>
            Cookies d'authentification : nous utilisons des cookies pour stocker
            votre jeton d'authentification afin de vous permettre d'accéder à
            des zones sécurisées de notre site réservées aux utilisateurs
            enregistrés.
          </li>
          <li>
            Cookies d'analyse : nous utilisons des cookies pour collecter des
            informations sur la façon dont vous utilisez notre site, afin
            d'améliorer son fonctionnement et de mieux comprendre les besoins de
            nos utilisateurs.
          </li>
          <li>
            Cookies de préférences : nous utilisons des cookies pour enregistrer
            vos préférences de navigation, comme votre langue ou votre fuseau
            horaire, afin de vous offrir une expérience personnalisée et plus
            pratique.
          </li>
          <li>
            Cookies pour l'avatar : nous utilisons des cookies pour stocker
            votre avatar si vous avez choisi d'en ajouter un à votre compte.
          </li>
        </ul>
        <p className="notices-text">
          Nous ne collectons pas d'informations personnelles sensibles, telles
          que votre adresse ou votre numéro de téléphone, à partir des cookies
          que nous utilisons sur notre site.
        </p>
        <p className="notices-text">
          En utilisant notre site, vous acceptez l'utilisation de ces cookies
          conformément à notre politique de cookies. Si vous ne souhaitez pas
          que des cookies soient stockés sur votre appareil, vous pouvez
          modifier les paramètres de votre navigateur pour désactiver les
          cookies. Veuillez noter que cela peut affecter le fonctionnement de
          certaines fonctionnalités de notre site.
        </p>
        <p className="notices-text">
          Nous nous engageons à protéger votre vie privée et à respecter vos
          choix en matière de cookies. Si vous avez des questions ou des
          préoccupations concernant notre politique de cookies, n'hésitez pas à
          nous contacter à l'adresse{" "}
          <span style={{ color: "#d2601a" }}>chantelavie13390@gmail.com</span>.
        </p>
        <h1 className="notices-title">Mentions légales</h1>
        <p className="notices-text">
          Le site web chantelavie.fr est édité par Chante la vie, une association
          loi 1901 enregistrée sous le numéro W133015844 et dont
          le siège social est situé à : Pl. de la Libération, 13390 Auriol.
        </p>
        <p className="notices-text">
          Responsable de la publication :<br /> Mr. Lucciarini Roger
        </p>
        <p className="notices-text">
          Hébergeur :<br /> OVHcloud, 2 rue Kellermann, 59100 Roubaix, France,
          +33 9 72 10 10 07, support@ovh.com
        </p>
        <p className="notices-text">
          Propriété intellectuelle :<br /> Le contenu du site web chantelavie.fr
          est la propriété exclusive de Chante la vie et est protégé par les
          lois françaises et internationales sur la propriété intellectuelle.
          Toute reproduction, représentation, modification, publication,
          transmission, dénaturation, totale ou partielle du site ou de son
          contenu, par quelque procédé que ce soit, et sur quelque support que
          ce soit est interdite, sauf autorisation expresse et préalable de
          Chante la vie.
        </p>
        <p className="notices-text">
          Données personnelles :<br /> Les informations recueillies sur ce site
          sont destinées à Chante la vie. Elles font l’objet d’un traitement
          informatique destiné à la gestion de l'association. Conformément à la
          loi "informatique et libertés" du 6 janvier 1978 modifiée en 2004,
          vous bénéficiez d'un droit d'accès et de rectification aux
          informations qui vous concernent, que vous pouvez exercer en nous
          contactant à l'adresse{" "}
          <span style={{ color: "#d2601a" }}>chantelavie13390@gmail.com</span>.
          Vous pouvez également, pour des motifs légitimes, vous opposer au
          traitement des données vous concernant.
        </p>
        <p className="notices-text">
          Liens hypertextes :<br /> Le site web chantelavie.fr peut contenir des
          liens hypertextes vers d’autres sites internet. La responsabilité de
          Chante la vie ne saurait être engagée quant au contenu de ces sites et
          à l’utilisation qui en est faite par les utilisateurs.
        </p>
        <p className="notices-text">
          Modification des mentions légales :<br /> Chante la vie se réserve le
          droit de modifier les présentes mentions légales à tout moment, sans
          préavis. L'utilisateur est donc invité à les consulter régulièrement.
        </p>
        <p className="notices-text">
          Contact :<br /> Pour toute question ou demande concernant le site web
          chantelavie.fr ou les services proposés, vous pouvez contacter Chante
          la vie à l'adresse suivante : Pl. de la Libération, 13390 Auriol, ou par
          e-mail à l'adresse suivante :{" "}
          <span style={{ color: "#d2601a" }}>chantelavie13390@gmail.com</span>.
        </p>
      </div>
    </>
  );
};

export default Notices;
