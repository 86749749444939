import React, { useState, useEffect } from "react";
import "./newPassword.css";
import { useNavigate, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import newPwdIcon from "../../assets/images/unlock.png";
import { toast } from "react-toastify";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const navigate = useNavigate();
  const { reset_token } = useParams();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  function showPassword() {
    var x = document.getElementById("input-pwd");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function showPasswordConfirm() {
    var x = document.getElementById("input-pwd-confirm");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function errorNotify() {
    toast.error("Les mots de passe ne sont pas identiques", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorNotify2() {
    toast.error(
      "Une erreur s'est produite lors de la réinitialisation de votre mot de passe.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  }

  function successNotify() {
    toast.success("Mot de passe modifié avec succès", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function fetchData(e) {
    e.preventDefault();
    if (password !== passwordConfirm) {
      errorNotify();
      return;
    }
    fetch(`https://clv-app.fly.dev/users/password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          reset_password_token: reset_token,
          password: password,
          password_confirmation: passwordConfirm,
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          navigate("/connexion");
          successNotify();
        } else {
          errorNotify2();
        }
      })
      .catch((error) => {
        errorNotify2();
      });
  }

  return (
    <>
      <div className="new-pwd-container">
        <div className="new-pwd-top">
          <img className="new-pwd-icon" src={newPwdIcon} alt="new-pwd" />
          <h2 className="new-pwd-title">Réinitialisation du mot de passe</h2>
        </div>
        <form className="box" id="new-pwd-form" onSubmit={fetchData}>
          <p className="info-registration">Entrez un nouveau mot de passe</p>
          <input
            className="feedback-input"
            id="input-pwd"
            type="password"
            name="password"
            placeholder="Nouveau mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="checkbox-container">
            <label className="text-muted">
              <input type="checkbox" onClick={showPassword} />
              Afficher le mot de passe
            </label>
          </div>
          <input
            className="feedback-input"
            id="input-pwd-confirm"
            type="password"
            name="password"
            placeholder="Confirmer mot de passe"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          <div className="checkbox-container">
            <label className="text-muted">
              <input type="checkbox" onClick={showPasswordConfirm} />
              Afficher le mot de passe
            </label>
          </div>
          <br />
          <br />
          <button type="submit">Réinitialiser</button>
        </form>
      </div>
    </>
  );
};

export default NewPassword;
