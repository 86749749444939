import React, { useEffect, useState } from "react";
import "./profile.css";
import { useAtomValue, useSetAtom, useAtom } from "jotai";
import {
  userAtom,
  JWT,
  isAdmin,
  avatarAtom,
  lastAvatarFileAtom,
} from "store/atoms";
import { Link } from "react-router-dom";
import editProfilLogo from "../../assets/images/edit-profil2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import DashboardAdmin from "components/DashboardAdmin";

const Profile = () => {
  const user = useAtomValue(userAtom);
  const jwt = useAtomValue(JWT);
  const [avatar, setAvatar] = useAtom(avatarAtom);
  const [lastAvatarFile, setLastAvatarFile] = useAtom(lastAvatarFileAtom);
  const setUser = useSetAtom(userAtom);
  const setToken = useSetAtom(JWT);
  const setAdmin = useSetAtom(isAdmin);
  const admin = useAtomValue(isAdmin);
  var dateNow = new Date();
  const navigate = useNavigate();
  const [userEvents, setUserEvents] = useState([]);
  var participationArray = [];

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    window.scrollTo(0, 0);

    fetch("https://clv-app.fly.dev/member-data", {
      method: "get",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        saveAvatar(response.avatar);
      });

    fetch("https://clv-app.fly.dev/participations/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        response.map((participation) => {
          if (
            participation.user_id === user.id &&
            !participationArray.includes(participation)
          ) {
            participationArray.push(participation);
          }
        });
        fetch("https://clv-app.fly.dev/events/", {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            var eventsFiltered = [];
            participationArray.map((participation) => {
              eventsFiltered.push(
                response.filter(
                  (evnt) =>
                    evnt.id === participation.event_id &&
                    new Date(evnt.start_date) > dateNow
                )
              );
            });
            setUserEvents(eventsFiltered);
          });
      });
  }, [avatar, lastAvatarFile, user.id]);

  function saveAvatar(datas) {
    setAvatar(datas);
    Cookies.set("avatar", datas, {
      sameSite: "none",
      secure: true,
    });
    if (lastAvatarFile === "" && datas !== null) {
      const urlName = datas.substring(datas.lastIndexOf("/") + 1);
      fetch(datas)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new File([blob], `${urlName}`, {
              type: blob.type,
            })
        )
        .then((file) => {
          setLastAvatarFile(file);
          Cookies.set("lastAvatarFile", file, {
            sameSite: "none",
            secure: true,
          });
        });
    }
  }

  function deleteAccount() {
    fetch("https://clv-app.fly.dev/users", {
      method: "delete",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(() => {
        setToken("");
        setUser([]);
        setAdmin("false");
        setAvatar("");
        setLastAvatarFile("");
        successNotify();
        navigate("/");
      })
      .catch((error) => console.error(error));
  }

  function successNotify() {
    toast.success("Votre compte a bien été supprimé, à bientôt ! 👋", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <>
      <div data-aos="fade-down" className="profil-container">
        {admin === "true" ? <DashboardAdmin /> : null}
        <div className="profil-card">
          <div className="avatar-container">
            <img
              id="avatar-profil"
              style={{ width: "6em", height: "6em", borderRadius: "100px" }}
              src={
                avatar === null || avatar === ""
                  ? "https://res.cloudinary.com/dytmjg2cx/image/upload/v1680864769/360_F_209370065_JLXhrc5inEmGl52SyvSPeVB23hB6IjrR_t08uuc.jpg"
                  : avatar
              }
              alt="avatar"
            />
          </div>
          <div className="second-container">
            <div className="name-container">
              <h1>
                {user.first_name} {user.last_name}
              </h1>
            </div>
            <div className="contact-container">
              <div className="info-container">
                <h3>E-mail:</h3>
                <h3 className="profil-infos">{user.email}</h3>
                <h3 id="num">N° Tél:</h3>
                <h3 className="profil-infos">{user.phone}</h3>
                <h3 id="city">Ville:</h3>
                <h3 className="profil-infos">{user.city}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="profil-btn-container">
          <Link
            to="/editer-profil"
            className="edit-profil-btn"
            style={{ textDecoration: "none" }}
          >
            <img
              style={{ width: "30px", height: "30px", marginRight: "5px" }}
              src={editProfilLogo}
              alt="logo editer profil"
            />
            Éditer profil
          </Link>
        </div>
        <div className="profil-event-card">
          <div style={{ textAlign: "center", width: "100%" }}>
            <h1 className="profil-events-title">Mon suivi d'évènements :</h1>
          </div>
          <h3 style={{ fontFamily: "Poppins" }}>
            {userEvents.length === 0
              ? "Vous ne partipez à aucun évènement"
              : ""}
          </h3>
          {userEvents.map(
            (evnt) =>
              evnt[0] && (
                <div className="profil-event" key={evnt[0].id}>
                  <img
                    className="profil-event-img"
                    src={evnt[0].image_url}
                    alt="event-img"
                  />
                  <div className="profil-event-title-container">
                    <h2 className="profil-event-title">{evnt[0].title}</h2>
                    <h3
                      className="profil-event-text"
                      style={{
                        color: "whitesmoke",
                        fontSize: "medium",
                        marginTop: "-15px",
                        marginLeft: "5px",
                      }}
                    >
                      🗓{" "}
                      {evnt[0].start_date
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("/")}{" "}
                      à {evnt[0].start_hour}
                    </h3>

                    <h3
                      className="profil-event-text"
                      style={{
                        color: "whitesmoke",
                        marginTop: "-10px",
                        marginLeft: "5px",
                      }}
                    >
                      🌎 {evnt[0].location}
                    </h3>
                  </div>
                  <div className="profil-event-date-container">
                    <Link
                      style={{ textDecoration: "none", marginBottom: "-30px" }}
                      className="eventshow-btn"
                      to={`/evenement/${evnt[0].id}`}
                    >
                      Voir
                    </Link>
                  </div>
                </div>
              )
          )}
        </div>
        <button
          className="delete-account-btn"
          onClick={() => {
            if (
              window.confirm("Voulez-vous vraiment supprimer votre compte ?")
            ) {
              deleteAccount();
            }
          }}
        >
          Supprimer mon compte
        </button>
      </div>
    </>
  );
};

export default Profile;
