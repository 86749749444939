import React, { useState, useEffect } from "react";
import "./book.css";
import AOS from "aos";
import "aos/dist/aos.css";
import quoteRight from "../../assets/images/quote-right.png";
import quoteLeft from "../../assets/images/quote-left.png";
import { useAtomValue } from "jotai";
import { userAtom, JWT, isAdmin } from "store/atoms";
import { useNavigate } from "react-router-dom";
import { Scrollchor, easeOutQuad } from "react-scrollchor";
import arrowTop from "../../assets/images/top.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import trashLogo from "../../assets/images/trash.png";
import warningLogo from "../../assets/images/warning.png";
import { toast } from "react-toastify";
import bookImage from "../../assets/images/book2.jpeg";

const Book = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [lastReview, setLastReview] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedReviewID, setSelectedReviewID] = useState(null);
  const user = useAtomValue(userAtom);
  const jwt = useAtomValue(JWT);
  const admin = useAtomValue(isAdmin);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    window.scrollTo(0, 0);
    fetch("https://clv-app.fly.dev/reviews/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setReviewList(response);
        setIsLoading(false);
      });
  }, []);

  function createReview() {
    if (firstname === "" && lastname === "") {
      errorNotify();
      return;
    }
    fetch("https://clv-app.fly.dev/reviews/", {
      method: "post",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        review: {
          content: lastReview,
          user_id: user.id,
          author_firstname: firstname,
          author_lastname: lastname,
        },
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.error(err));
    infoNotify();
    navigate("/");
  }

  function deleteReview() {
    fetch(`https://clv-app.fly.dev/reviews/${selectedReviewID}`, {
      method: "delete",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        successNotify();
        navigate("/");
        setSelectedReviewID(null);
        cancelPopup();
      })
      .catch((error) => console.error(error));
  }

  function successNotify() {
    toast.success("Le commentaire a bien été supprimé", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function infoNotify() {
    toast.info("Message inscrit dans le livre d'or, merci !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorNotify() {
    toast.error(
      "Veuilez renseigner au minimum votre nom ou votre prénom pour laisser un message dans le livre d'or",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  }

  function setFocus() {
    document.getElementsByClassName("review-textarea")[0].focus();
  }

  function activePopup() {
    document.getElementsByClassName("warning-delete-review")[0].style.display =
      "flex";
    document.getElementsByClassName("greying")[0].style.opacity = "0.3";
    document.getElementsByClassName("greying")[0].style.backgroundColor =
      "#484a4a";
  }

  function cancelPopup() {
    document.getElementsByClassName("warning-delete-review")[0].style.display =
      "none";
    document.getElementsByClassName("greying")[0].style.opacity = "1";
    document.getElementsByClassName("greying")[0].style.backgroundColor =
      "whitesmoke";
  }

  return (
    <>
      <div className="warning-delete-review">
        <div className="first-container-warning-delete-review">
          <img
            src={warningLogo}
            alt="warning-logo"
            style={{ width: "70px", height: "70px" }}
          />
          <p>
            Attention, vous êtes sur le point de supprimer définitivement ce
            commentaire.
          </p>
        </div>
        <div className="btn-container-warning-delete-review">
          <button
            className="delete-btn"
            type="button"
            onClick={(e) => deleteReview(e.target)}
          >
            Supprimer
          </button>
          <button className="cancel-btn" type="button" onClick={cancelPopup}>
            Annuler
          </button>
        </div>
      </div>
      <div id="top-book-anchor" className="book-img-container">
        <img src={bookImage} className="book-img" alt="reviews-top-img" />
        <div className="book-top">
          <h1 className="book-title">Livre d'or</h1>
        </div>
      </div>

      <div className="greying">
        <div className="book-container">
          <div className="book-card">
            <h3 className="book-description">
              N'hésitez pas à nous faire parvenir vos commentaires,
              appréciations, critiques, positives comme négatives, sur notre
              chorale, à propos de nos concerts, sur notre site, etc ... et pour
              tout autre aspect concernant notre activité de chant choral. Vos
              contributions nous permettront de continuer à nous améliorer afin
              de satisfaire au mieux notre public. Un grand merci par avance et
              au plaisir de partager la joie du chant choral en votre compagnie.
            </h3>
          </div>
          <Scrollchor
            to="#review-anchor"
            className="new-review-btn"
            animate={{ duration: 1000, easing: easeOutQuad }}
            afterAnimate={() => setFocus()}
          >
            Laisser un commentaire
          </Scrollchor>

          {isLoading ? (
            <FontAwesomeIcon
              style={{ paddingTop: "100px", paddingBottom: "100px" }}
              size="5x"
              color="#1d3c45"
              className="spinner"
              icon={faSpinner}
            ></FontAwesomeIcon>
          ) : null}
          {reviewList.length === 0 ? (
            <>
              <div className="first-message">
                <h4>Soyez le premier à nous laisser un message 🙂</h4>
              </div>
            </>
          ) : (
            <></>
          )}
          {reviewList.map((review) => (
            <div key={review.id} data-aos="fade-right" className="review-card">
              <div className="review-user-info">
                <div className="review-name-container">
                  <h4 className="review-name">
                    &nbsp;{review.author_firstname}&nbsp;&nbsp;
                    {review.author_lastname}
                  </h4>

                  <p>&nbsp;&nbsp;à écrit le &nbsp;{review.created_at}</p>
                </div>
                {admin === "true" ? (
                  <img
                    src={trashLogo}
                    alt="trash-logo"
                    className="trashlogo"
                    onClick={() => {
                      setSelectedReviewID(review.id);
                      activePopup();
                    }}
                  />
                ) : (
                  <p></p>
                )}
              </div>
              <div className="review-content">
                <p style={{ padding: "5px", margin: "10px" }}>
                  <img
                    src={quoteLeft}
                    style={{ width: "40px", height: "40px", padding: "5px" }}
                    alt="guillemets gauche"
                  />
                  {review.content}&nbsp;&nbsp;
                  <img
                    src={quoteRight}
                    style={{ width: "40px", height: "40px" }}
                    alt="guillemets droit"
                  />
                </p>
              </div>
            </div>
          ))}
          <Scrollchor
            to="#top-book-anchor"
            className="arrow-top"
            animate={{ duration: 1000, easing: easeOutQuad }}
          >
            <img src={arrowTop} alt="fleche ancrage haut" />
          </Scrollchor>

          <div id="review-anchor" className="new-review-form">
            <textarea
              className="review-textarea"
              type="text"
              name="review-content"
              placeholder="Entrez votre message ici .."
              onChange={(e) => setLastReview(e.target.value)}
            />
          </div>
          <input
            name="last_name"
            onChange={(e) => setLastname(e.target.value)}
            style={{ width: "90%" }}
            type="text"
            className="feedback-input"
            placeholder="Nom"
          />
          <input
            name="first_name"
            onChange={(e) => setFirstname(e.target.value)}
            style={{ width: "90%" }}
            type="text"
            className="feedback-input"
            placeholder="Prénom"
          />
          <button
            className="new-review-send-btn"
            type="button"
            onClick={createReview}
          >
            Envoyer ✓
          </button>
        </div>
      </div>
    </>
  );
};

export default Book;
