import locationLogo from "../../assets/images/location-dark.png";

export const LocationPin = ({ text }) => (
  <div className="pin">
    <img
      style={{ width: "30px", height: "30px" }}
      src={locationLogo}
      alt="here-logo"
    />
    <p className="pin-text">{text}</p>
  </div>
);
