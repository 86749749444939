import React, { useEffect, useState } from "react";
import "./pictures.css";
import { useAtomValue } from "jotai";
import { JWT, isAdmin } from "store/atoms";
import crossLogo from "../../assets/images/cross.png";
import trashLogo from "../../assets/images/trash.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const Pictures = () => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [album, setAlbum] = useState([]);
  const [selectedID, setSelectedID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const jwt = useAtomValue(JWT);
  const admin = useAtomValue(isAdmin);

  useEffect(() => {
    const fetchPictures = async () => {
      try {
        const response = await fetch("https://clv-app.fly.dev/pictures", {
          method: "GET",
          headers: {
            Authorization: jwt,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setAlbum(data.pictures);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    window.scrollTo(0, 0);
    fetchPictures();
  }, [jwt]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("picture[title]", title);
    formData.append("picture[image]", image);

    try {
      const response = await fetch("https://clv-app.fly.dev/pictures", {
        method: "POST",
        headers: {
          Authorization: jwt,
        },
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        successNotify();
      }
    } catch (error) {
      errorNotify();
      console.error(error);
    }
  };

  const deletePicture = async (id) => {
    try {
      const response = await fetch(`https://clv-app.fly.dev/pictures/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: jwt,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        deleteSuccessNotify();
      }
    } catch (error) {
      console.error(error);
    }
    closePopup();
  };

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleImageChange(event) {
    setImage(event.target.files[0]);
  }

  function openPopup(id) {
    setSelectedID(id);
    document.getElementsByClassName("pictures-container")[0].style.opacity =
      "0.3";
    document.getElementsByClassName(
      "pictures-container"
    )[0].style.backgroundColor = "#484a4a";
  }

  function closePopup() {
    setSelectedID(null);
    document.getElementsByClassName("pictures-container")[0].style.opacity =
      "1";
    document.getElementsByClassName(
      "pictures-container"
    )[0].style.backgroundColor = "whitesmoke";
  }

  function successNotify() {
    toast.success("Image ajoutée avec succès", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function deleteSuccessNotify() {
    toast.success("Image supprimée avec succès", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function errorNotify() {
    toast.error("Une erreur est survenue, image non ajoutée", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <>
      <div className="pictures-container">
        {admin === "true" ? (
          <>
            <h2 className="pictures-dl-title">Télécharger une image</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  placeholder="Titre"
                  className="feedback-input"
                  name="title"
                  value={title}
                  onChange={handleTitleChange}
                />
              </div>
              <div>
                <input
                  type="file"
                  id="image"
                  style={{ marginTop: "10px", marginBottom: "30px" }}
                  name="image"
                  onChange={handleImageChange}
                />
              </div>
              <button type="submit">Télécharger</button>
            </form>
          </>
        ) : null}
        {isLoading ? (
          <FontAwesomeIcon
            style={{ paddingTop: "100px", paddingBottom: "100px" }}
            size="5x"
            color="#1d3c45"
            className="spinner"
            icon={faSpinner}
          ></FontAwesomeIcon>
        ) : null}
        <div className="pictures-grid">
          {album.map((picture) => (
            <div
              key={picture.id}
              className="picture-container"
              onClick={() => openPopup(picture.id)}
            >
              <img
                className="picture"
                src={picture.cloudinary_url}
                alt={picture.title}
              />
              <div className="picture-title-container">
                <h4 className="picture-title">{picture.title}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedID && (
        <div className="picture-popup">
          <div className="picture-popup-top">
            <h4 className="popup-top-title">
              {album.find((picture) => picture.id === selectedID)?.title}
            </h4>
            {admin === "true" ? (
              <img
                onClick={() =>
                  deletePicture(
                    album.find((picture) => picture.id === selectedID)?.id
                  )
                }
                className="popup-top-delete"
                src={trashLogo}
                alt="trash"
              />
            ) : null}
            <img
              onClick={closePopup}
              className="popup-top-close"
              src={crossLogo}
              alt="close"
            />
          </div>
          <img
            className="picture-zoom"
            src={
              album.find((picture) => picture.id === selectedID)?.cloudinary_url
            }
            alt="img-popup"
          />
        </div>
      )}
    </>
  );
};

export default Pictures;
