import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.scss";
import Home from "./pages/Home";
import Events from "pages/Events";
import Event from "pages/Event";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Book from "pages/Book";
import RegisterForm from "components/RegisterForm";
import LoginForm from "components/LoginForm";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Profile from "pages/Profile";
import EditProfil from "pages/EditProfil";
import Footer from "components/Footer";
import Pictures from "pages/Pictures";
import Videos from "pages/Videos";
import ForgotPassword from "pages/ForgotPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewPassword from "pages/NewPassword";
import EmailSent from "pages/EmailSent";
import Notices from "pages/Notices";

const App = () => {
  return (
    <>
      <ToastContainer />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/a-propos" element={<About />} />
          <Route path="/evenements" element={<Events />} />
          <Route path="/evenement/:id" element={<Event />} />
          <Route path="/galerie" element={<Gallery />} />
          <Route path="/galerie/photos" element={<Pictures />} />
          <Route path="/galerie/videos" element={<Videos />} />
          <Route path="/livre-dor" element={<Book />} />
          <Route path="/inscription" element={<RegisterForm />} />
          <Route path="/connexion" element={<LoginForm />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profil" element={<Profile />} />
          <Route path="/editer-profil" element={<EditProfil />} />
          <Route path="/oubli-mdp" element={<ForgotPassword />} />
          <Route path="/new-password/:reset_token" element={<NewPassword />} />
          <Route path="/email-sent" element={<EmailSent />} />
          <Route path="/notices" element={<Notices />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

createRoot(document.getElementById("root")).render(<App />);
