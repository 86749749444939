import React, { useState, useEffect } from "react";
import "./forgotPassword.css";
import sendLogo from "../../assets/images/send.png";
import forgotPwdIcon from "../../assets/images/forgot-pwd.png";
import backIcon from "../../assets/images/back.png";
import { Link, useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { JWT } from "store/atoms";
import AOS from "aos";
import "aos/dist/aos.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const jwt = useAtomValue(JWT);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  function fetchData() {
    fetch("https://clv-app.fly.dev/users/password", {
      method: "post",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          email: email,
        },
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.error(err));
    navigate("/email-sent");
  }

  return (
    <>
      <div className="card" data-aos="fade-down">
        <form className="box" id="forgot-pwd-form" onSubmit={fetchData}>
          <img src={forgotPwdIcon} className="forgot-pwd-icon" alt="key-lock" />
          <h1 className="log-title">Mot de passe oublié ?</h1>
          <p className="info-registration">
            Veuillez renseigner votre adresse e-mail, vous allez recevoir un
            e-mail pour réinitialiser votre mot de passe.
          </p>
          <input
            id="email-input"
            type="text"
            name="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" id="forgot-pwd-btn">
            <img
              className="send-logo"
              style={{ marginTop: "5px" }}
              src={sendLogo}
              alt="logo-envoi-mail"
            />
          </button>
        </form>
      </div>
      <div className="back-btn-container">
        <Link
          to="/connexion"
          className="back-btn"
          style={{ textDecoration: "none" }}
        >
          <img src={backIcon} className="back-icon" alt="back icon" />
          &nbsp;&nbsp;&nbsp;Retour
        </Link>
      </div>
    </>
  );
};

export default ForgotPassword;
