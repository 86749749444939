import React, { useState, useEffect } from "react";
import { JWT } from "store/atoms";
import { useAtomValue } from "jotai";
import "./dashboardAdmin.css";

const DashboardAdmin = () => {
  var dateNow = new Date();
  const [eventsList, setEventsList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [participations, setParticipations] = useState([]);
  const jwt = useAtomValue(JWT);

  useEffect(() => {
    fetch("https://clv-app.fly.dev/events/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        var futurArray = [];
        response.map((event) => {
          var date = new Date(event.start_date);
          if (date > dateNow) {
            futurArray.push(event);
            setEventsList(futurArray);
          } else {
            return;
          }
        });
        response.forEach((event) => {
          fetch(`https://clv-app.fly.dev/events/${event.id}/participations`, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              event.participantsCount = response.length;
              setParticipations((prevParticipations) => [
                ...prevParticipations,
                { eventId: event.id, participantsCount: response.length },
              ]);
            });
        });
      });
  }, []);

  useEffect(() => {
    fetch("https://clv-app.fly.dev/all-users/", {
      method: "get",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        var usersArray = [];
        response.map((user) => {
          usersArray.push(user);
          setUsersList(usersArray);
        });
      });
  }, []);

  useEffect(() => {
    fetch("https://clv-app.fly.dev/reviews/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setReviewsList(response);
      });
  }, []);

  return (
    <>
      <div className="dashboard-card">
        <div className="dashboard-title-container">
          <h2 className="dashboard-title">Dashboard admin</h2>
        </div>
        <div className="dashboard-content">
          <div className="dashboard-case">
            <h3 className="dashboard-case-title">
              Nombre d'utilisateurs inscrits :
            </h3>
            <span className="dashboard-case-result">{usersList.length}</span>
          </div>
          <div className="dashboard-case">
            <h3 className="dashboard-case-title">Nombre de commentaires :</h3>
            <span className="dashboard-case-result">{reviewsList.length}</span>
          </div>
          <div className="dashboard-events-container">
            <h3>Évènements :</h3>
            {eventsList.map((evnt) => (
              <div key={evnt.id} className="dashboard-event">
                <h4 style={{ marginLeft: "3%" }}>
                  {evnt.title} à {evnt.location} le{" "}
                  {evnt.start_date.slice(0, 10).split("-").reverse().join("/")}{" "}
                  :
                </h4>
                <span style={{ marginRight: "3%" }}>
                  {participations.find((p) => p.eventId === evnt.id)
                    ?.participantsCount || 0}{" "}
                  inscrits
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAdmin;
