import React, { useEffect, useState } from "react";
import "./editProfil.css";
import editProfilLogo2 from "../../assets/images/edit-profil2.png";
import { useAtomValue, useAtom, useSetAtom } from "jotai";
import {
  avatarAtom,
  JWT,
  userAtom,
  defaultAvatarFileAtom,
  lastAvatarFileAtom,
} from "store/atoms";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import backIcon from "../../assets/images/back.png";

const EditProfil = () => {
  const [user, setUser] = useAtom(userAtom);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [file, setFile] = useState(null);
  const jwt = useAtomValue(JWT);
  const navigate = useNavigate();
  const setAvatar = useSetAtom(avatarAtom);
  const defaultAvatarFile = useAtomValue(defaultAvatarFileAtom);
  const [lastAvatarFile, setLastAvatarFile] = useAtom(lastAvatarFileAtom);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function editUser() {
    let form = document.getElementsByClassName("profil-form")[0];
    let formData = Array.from(new FormData(form));
    let current_user = user;
    current_user[formData[0][0]] =
      formData[0][1] === "" ? current_user[formData[0][0]] : formData[0][1];
    current_user[formData[1][0]] =
      formData[1][1] === "" ? current_user[formData[1][0]] : formData[1][1];
    current_user[formData[2][0]] =
      formData[2][1] === "" ? current_user[formData[2][0]] : formData[2][1];
    current_user[formData[3][0]] =
      formData[3][1] === "" ? current_user[formData[3][0]] : formData[3][1];
    current_user[formData[4][0]] =
      formData[4][1] === "" ? current_user[formData[4][0]] : formData[4][1];
    updateUser(current_user);
  }

  function updateUser(datas) {
    setUser(datas);
    saveInDB(datas);
  }

  function saveInDB(datas) {
    Cookies.set("user", JSON.stringify(datas), {
      sameSite: "none",
      secure: true,
    });

    const formData = new FormData();
    if (lastname !== "") {
      formData.append("last_name", lastname);
    } else {
      formData.append("last_name", user.last_name);
    }
    if (firstname !== "") {
      formData.append("first_name", firstname);
    } else {
      formData.append("first_name", user.first_name);
    }
    if (phone !== "") {
      formData.append("phone", phone);
    } else {
      formData.append("phone", user.phone);
    }
    if (city !== "") {
      formData.append("city", city);
    } else {
      formData.append("city", user.city);
    }
    if (file !== null) {
      formData.append("avatar", file);
    } else if (file === null && lastAvatarFile !== "") {
      formData.append("avatar", lastAvatarFile);
    } else if (
      file === null &&
      lastAvatarFile === "" &&
      user.avatar === undefined
    ) {
      formData.append("avatar", defaultAvatarFile);
    }

    fetch("https://clv-app.fly.dev/update-user", {
      method: "post",
      headers: {
        Authorization: jwt,
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => saveAvatar(response.avatar));
    if (file !== null) {
      setLastAvatarFile(file);
      Cookies.set("lastAvatarFile", file, {
        sameSite: "none",
        secure: true,
      });
    }
    navigate("/profil");
    successNotify();
  }

  function saveAvatar(datas) {
    setAvatar(datas);
    Cookies.set("avatar", datas, {
      sameSite: "none",
      secure: true,
    });
  }

  function successNotify() {
    toast.success("Le profil a été modifié avec succès", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }
  return (
    <>
      <div className="edit-profil-container">
        <div className="edit-profil-title">
          <img src={editProfilLogo2} alt="logo editer profil" />
          <h2>Editer mon profil</h2>
        </div>
        <form
          className="profil-form"
          encType="multipart/form-data"
          onSubmit={(e) => {
            e.preventDefault();
            editUser();
          }}
        >
          <input
            name="last_name"
            defaultValue={user.last_name}
            onChange={(e) => setLastname(e.target.value)}
            style={{ width: "90%" }}
            type="text"
            className="feedback-input"
            placeholder="Nom"
          />
          <input
            name="first_name"
            defaultValue={user.first_name}
            onChange={(e) => setFirstname(e.target.value)}
            style={{ width: "90%" }}
            type="text"
            className="feedback-input"
            placeholder="Prénom"
          />
          <input
            name="phone"
            defaultValue={user.phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{ width: "90%" }}
            type="tel"
            className="feedback-input"
            placeholder="Téléphone"
          />
          <input
            name="city"
            defaultValue={user.city}
            onChange={(e) => setCity(e.target.value)}
            style={{ width: "90%" }}
            type="text"
            className="feedback-input"
            placeholder="Ville"
          />
          <h3>Modifiez votre photo de profil :</h3>
          <input
            type="file"
            name="avatar"
            accept="image/png, image/jpeg"
            style={{ width: "90%" }}
            onChange={(e) => setFile(e.target.files[0])}
          />
          <button className="edit-profil-confirm-btn" type="submit">
            ✓ Valider
          </button>
        </form>
      </div>
      <div
        className="back-btn-container"
        style={{ justifyContent: "flex-end" }}
      >
        <Link
          to="/profil"
          className="back-btn"
          style={{
            textDecoration: "none",
            width: "30%",
            marginTop: "30px",
            marginRight: "2em",
            justifyContent: "center",
          }}
        >
          <img src={backIcon} className="back-icon" alt="back icon" />
          &nbsp;&nbsp;&nbsp;Profil
        </Link>
      </div>
    </>
  );
};

export default EditProfil;
