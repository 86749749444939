import React, { useEffect, useState } from "react";
import "./event.css";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Map from "components/Map";
import backIcon from "../../assets/images/back.png";
import { toast } from "react-toastify";
import { useAtomValue } from "jotai";
import { JWT, isAdmin, userAtom } from "store/atoms";
import Cookies from "js-cookie";

const Event = () => {
  const params = useParams();
  const navigate = useNavigate();
  const admin = useAtomValue(isAdmin);
  const jwt = useAtomValue(JWT);
  const user = useAtomValue(userAtom);
  var dateNow = new Date();
  const [currentEvent, setCurrentEvent] = useState("");
  const [currentLat, setCurrentLat] = useState("");
  const [currentLng, setCurrentLng] = useState("");
  const [assist, setAssist] = useState("no");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`https://clv-app.fly.dev/events/${params.id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setCurrentEvent(response);
        setCurrentLat(Number(response.latitude));
        setCurrentLng(Number(response.longitude));
      });
    fetch(`https://clv-app.fly.dev/participations/`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        var participationArray = [];
        response.map((participation) => {
          if (participation.user_id === user.id) {
            participationArray.push(participation);
          }
        });
        var idArray = [];
        participationArray.map((participation) => {
          idArray.push(participation.event_id);
        });
        if (idArray.includes(Number(params.id))) {
          setAssist("yes");
        } else {
          setAssist("no");
        }
      });
  }, []);


  function deleteEvent() {
    fetch(`https://clv-app.fly.dev/events/${params.id}`, {
      method: "delete",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        successNotify();
        navigate("/evenements");
        return response.json();
      })

      .catch((error) => console.error(error));
  }

  function newParticipation() {
    fetch("https://clv-app.fly.dev/participations", {
      method: "post",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        participation: {
          user_id: user.id,
          event_id: params.id,
        },
      }),
    })
      .then((response) => {
        successNotify2();
        setAssist("yes");
        navigate("/evenements");
        return response.json();
      })

      .catch((error) => console.error(error));
  }

  function deleteParticipation() {
    fetch(`https://clv-app.fly.dev/participations/`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        var participationArray = [];
        response.map((participation) => {
          if (participation.user_id === user.id) {
            participationArray.push(participation);
          }
        });
        var currentParticipation = [];
        participationArray.map((participation) => {
          if (participation.event_id === currentEvent.id) {
            currentParticipation.push(participation);
          }
        });
        fetch(
          `https://clv-app.fly.dev/participations/${currentParticipation[0].id}`,
          {
            method: "delete",
            headers: {
              Authorization: jwt,
              "Content-Type": "application/json",
            },
          }
        ).then(() => {
          successNotify3();
          setAssist("no");
        });
      })
      .catch((error) => console.error(error));
  }

  function selectPast() {
    Cookies.set("isPast", "true", {
      sameSite: "none",
      secure: true,
    });
  }

  function selectFutur() {
    Cookies.set("isPast", "false", {
      sameSite: "none",
      secure: true,
    });
  }

  function successNotify() {
    toast.success(`L'évènement ${currentEvent.title} à bien été supprimé`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  function successNotify2() {
    toast.success(
      `Vous participez à l'évènement ${
        currentEvent.title
      } qui aura lieu le ${currentEvent.start_date
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/")} à ${currentEvent.start_hour}`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  }

  function successNotify3() {
    toast.success(
      `Vous ne participez plus à l'évènement ${currentEvent.title}`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  }

  return (
    <>
      <div className="eventshow-container">
        <div className="eventshow-title-container">
          <h1 className="eventshow-title">{currentEvent.title}</h1>
        </div>
        <div className="img-map-container">
          <div className="eventshow-image-container">
            <img
              className="test-img-show"
              src={currentEvent.image_url}
              alt=""
            />
          </div>
          <div className="eventshow-map-container">
            <Map center={[currentLat, currentLng]} />
          </div>
        </div>
        <div className="eventshow-info-container">
          <div className="eventshow-info-left">
            <div style={{ display: "flex" }}>
              <h4 className="eventshow-info-title">Lieu :</h4>
              <h3 className="eventshow-info">&nbsp;{currentEvent.location}</h3>
            </div>
            <h4 className="eventshow-info-title">Description :</h4>
            <p className="eventshow-info" style={{ marginTop: "-10px" }}>
              &nbsp;{currentEvent.description}&nbsp;
            </p>

          </div>
          <div className="eventshow-info-right">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "100%",
                textAlign: "center",
              }}
            >
              <h4 className="eventshow-info-title">Adresse complète :</h4>
              <h3 className="eventshow-info" style={{ marginTop: "0" }}>
                {currentEvent.address}
              </h3>
            </div>
            <div
              className="eventshow-date-container"
              style={{ textAlign: "center" }}
            >
              <h1
                style={{
                  color: "whitesmoke",
                  backgroundColor: "#1d3c45",
                  padding: "10px",
                }}
              >
                {currentEvent
                  ? "le " +
                    currentEvent.start_date
                      .slice(0, 10)
                      .split("-")
                      .reverse()
                      .join("/")
                  : currentEvent.start_date}{" "}
                à {currentEvent.start_hour}
              </h1>
            </div>
          </div>
        </div>
        <div className="eventshow-btn-container">
          {new Date(currentEvent.start_date) < dateNow || jwt === "" ? (
            <Link
              to="/evenements"
              onClick={selectPast}
              style={{ marginRight: "110px", textDecoration: "none" }}
              id="back-eventshow-btn"
            >
              <img
                src={backIcon}
                style={{ width: "40px", height: "40px" }}
                alt="back-logo"
              />
            </Link>
          ) : (
            <>
              <Link
                to={`/evenement/${currentEvent.id}`}
                onClick={
                  assist === "no" ? newParticipation : deleteParticipation
                }
                style={{ textDecoration: "none", marginRight: "10px" }}
                className="eventshow-btn"
              >
                {assist === "yes" ? "Je ne participe plus" : "Je participe"}
              </Link>
              <Link
                to="/evenements"
                onClick={selectFutur}
                style={{ marginRight: "110px", textDecoration: "none" }}
                id="back-eventshow-btn"
              >
                <img
                  src={backIcon}
                  style={{ width: "40px", height: "40px" }}
                  alt="back-logo"
                />
              </Link>
            </>
          )}
        </div>
        {admin === "true" ? (
          <button
            style={{ marginTop: "20px" }}
            className="delete-account-btn"
            onClick={() => {
              if (
                window.confirm(
                  `Voulez-vous vraiment supprimer l'évènement ${currentEvent.title} ?`
                )
              ) {
                deleteEvent();
              }
            }}
          >
            Supprimer cet évènement
          </button>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Event;
